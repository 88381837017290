<template>
<div>
    <v-card class="border-radius-sm" :class="!$vuetify.theme.dark ? 'bg-upload' : ''" flat>
        <div class="pa-2 d-flex justify-content-between">
            <v-btn :loading="$global.state.uploading" @click="handleFileImport" icon="icon"
                :color="icon ? 'info' : 'primary'" :class="icon ? '' : 'btn secondary--text'">
                <v-icon>fa-solid fa-paperclip</v-icon>
                <h3 v-if="!icon">{{ buttonText }}</h3>
            </v-btn>
            <v-spacer></v-spacer>
            <small class="red--text mt-2">{{ warnText }}</small>
            <input ref="uploader" class="d-none" type="file" :accept="accept" @change="onFileChanged"
                :multiple="multiple" />
        </div>
        <!-- <v-divider></v-divider> -->
        <v-card-text v-show="previews[0]">
            <div class="parent">
                <div class="child" v-for="(preivew, i) in previews" :key="i">
                    <v-badge offset-x="15" right color="transparent">
                        <template v-slot:badge>
                            <v-btn @click="deleteFile(i)" icon dark x-small class="pink btn-delete-file">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <v-img class="border-radius-sm" height="60" width="60" :src="preivew" />
                        <small>{{ files[0] ? `${(Math.round(+files[i].size / 1024) / 1000).toFixed(2)}MB` : 0 }}</small>
                    </v-badge>
                </div>
            </div>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
export default {
    props: ["accept", "buttonText", "formData", "endPoint", "icon", "multiple"],
    data() {
        return {
            warnText: "",
        };
    },

    computed: {
        ...mapState(["files", "previews"]),
    },

    methods: {
        deleteFile(i) {
            this.files.splice(i, 1);
            this.previews.splice(i, 1);
            // this.$store.commit("clearFiles");
        },

        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                }, {
                once: true
            }
            );

            this.$refs.uploader.click();
        },

        onFileChanged(e) {
            var selectedFiles = e.target.files;
            let validFiles = [];
            for (var i = 0; i < selectedFiles.length; i++) {
                if (selectedFiles[i] && selectedFiles[i]["type"].split("/")[0] === "image") {
                    validFiles.push(selectedFiles[i]);
                }
            }
            if (validFiles.length == 0) {
                this.$store.dispatch("toastification/setToast", {
                    message: `!! الرجاء تحديد صورة`,
                    type: "warning",
                });
                this.warnText = "!! الرجاء تحديد صورة";
                return;
            }


            this.$store.commit("clearFiles");
            for (var index = 0; index < validFiles.length; index++) {
                this.files.push(validFiles[index]);
                this.previews.push(URL.createObjectURL(validFiles[index]));

            }

            // let file = e.target.files[0];
            // if (file && file["type"].split("/")[0] === "image") {
            //     this.warnText = "";
            //     this.$store.commit("clearFiles");
            //     this.files.push(e.target.files[0]);
            //     this.previews.push(URL.createObjectURL(e.target.files[0]));
            // } else {
            //     this.$store.dispatch("toastification/setToast", {
            //         message: `!! الرجاء تحديد صورة`,
            //         type: "warning",
            //     });
            //     this.warnText = "!! الرجاء تحديد صورة";
            // }

            // console.log(this.files);
        },
    },
};
</script>

<style scoped>
.bg-upload {
    background: #f7f9fc;
}

.parent {
    display: flex;
    flex-wrap: wrap;
}

.child {
    display: inline-block;
    flex: 1 0 10%;
}

.btn-delete-file {
    padding: 10px;
    font-weight: bold;
}
</style>
